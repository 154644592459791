import React from 'react';
import logo from './yes-fam-logo.png';
import './App.css';

const App: React.FC = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h2>ernesto.lv</h2>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Šeit top jauna un stilīga mājaslapa.
        </p>
      </header>
    </div>
  );
}

export default App;
